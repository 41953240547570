<template>
  <div>
    <b-modal :active.sync="showModalUpdateUser" :can-cancel="false">
      <b-loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></b-loading>
      <div class="modals-bosy" id="profile-model" v-if="Object.values(paises).length > 0">
        <h3 class="title-profile-modal">{{ $t('myProfile') }}</h3>
        <p class="text-info">{{ $t('pleaseProfileInformation') }}.</p>
        <form @submit.prevent="validateBeforeLogin" autocomplete="off">
          <b-field
            :label="$t('fullName') + ':' "
            :message="errors.first('username')"
            :type="{'is-danger': errors.has('username')}"
          >
            <b-input
              :class="{ 'has-error' : errors.has('username') }"
              :placeholder="$t('fullName')"
              autofocus
              class="form-control form-control-sm"
              expanded
              icon="user"
              icon-pack="fa"
              id="username"
              name="username"
              type="text"
              v-model="name"
              v-validate="'required|min:2|regex:^([a-zA-ZÀ-ÿ\u00f1\u00d1]+)((\\s){1}[a-zA-ZÀ-ÿ\u00f1\u00d1]+)?((\\s){1}[a-zA-ZÀ-ÿ\u00f1\u00d1]+)?((\\s){1}[a-zA-ZÀ-ÿ\u00f1\u00d1]+)?((\\s){1})?$'"
            ></b-input>
          </b-field>
          <b-field
            :label="$t('email')+':'"
            :message="errors.first('emails')"
            :type="{'is-danger': errors.has('emails')}"
          >
            <b-input
              :class="{ 'has-error' : errors.has('emails') }"
              :placeholder="$t('email')"
              autocomplete="off"
              autofocus
              class="form-control form-control-sm"
              expanded
              icon="envelope"
              icon-pack="fa"
              id="emails"
              name="emails"
              type="email"
              v-model="email"
              v-validate="'required|email'"
            ></b-input>
          </b-field>
          <b-field :label="$t('country')+':'">
            <div
              :style="{ backgroundImage: 'url('+ bandera +') !important'}"
              class="country-banders"
            ></div>
            <b-select
              :change="changedPais(option)"
              class="form-control form-control-sm"
              icon="globe"
              icon-pack="fa"
              v-model="option"
            >
              <option :key="'countries' + key" :value="pais" v-for="(pais, key) in paises">{{ pais.label }}</option>
            </b-select>
          </b-field>
          <b-field
            :label="$t('phone')+':'"
            :message="errors.first('phone')"
            :type="{'is-danger': errors.has('phone')}"
          >
            <b-input
              :class="{ 'has-error' : errors.has('phone') }"
              :placeholder="'XXXXXXXXXX'"
              autocomplete="phone-new"
              autofocus
              class="form-control form-control-sm"
              expanded
              icon="phone"
              icon-pack="fa"
              id="phone"
              name="phone"
              type="text"
              v-mask="['##########']"
              v-model="phone"
              v-validate="validateTel"
            ></b-input>
          </b-field>
          <div class="btns-modal">
            <button @click="boolDialog" class="full-btn-modal" type="button">{{ $t('close') }}</button>
            <button
              :disabled="modified"
              :loading="modified"
              class="full-btn-modal btn-modal-fill"
              type="submit"
            >{{ $t('saveProfile') }}</button>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import authTypes from '@/store/types/auth';
import _ from 'lodash';
import callingCode from 'country-json/src/country-by-calling-code.json';
import BanderaPais from 'country-json/src/country-by-abbreviation.json';
export default {
  props: ['show', 'designUpd'],
  data () {
    return {
      modified: false,
      option: {
        value: 57,
        name: 'Colombia',
        label: 'Colombia ( +57 )',
        selected: true
      },
      indicativo: '57',
      country: 'Colombia',
      bandera: 'https://api.precompro.com/banderas/co.png',
      paises: [],
      isImageModalActive2: false,
      isLoading: false,
      name: null,
      email: null,
      phone: null,
      validateTel: ''
    };
  },
  mounted () {
    this.name = this.user.displayName;
    this.phone = this.user.phone;
    this.email = this.user.email;
    this.listPaises();
    if (this.user.indicativo !== null) {
      if (this.user.indicativo !== undefined) {
        this.indicativo = this.user.indicativo;
        if (this.indicativo == null) {
          let indicativoInfo = this.$store.getters['whiteLabel:vendor'].params.indicativeRegion;
          if (indicativoInfo === undefined || indicativoInfo == null) {
            indicativoInfo = 57;
          }
          this.indicativo = indicativoInfo;
        }
        this.indicativeParam(this.indicativo);
        this.validateTelIndicative(this.option.value);
      }
    }
  },
  computed: {
    ...mapGetters({
      editReservation: reservationTypes.getters.editReservation,
      showModalUpdateUser: whiteLabelTypes.getters.showModalUpdateUser,
      user: authTypes.getters.user
    })
  },
  methods: {
    ...mapActions({
      updateUser: authTypes.actions.updateUser
    }),
    validateTelIndicative (indicative) {
      const paisId = (indicative === null) ? 57 : parseInt(indicative);
      switch (paisId) {
        case 57:
          this.validateTel = 'required|min:10|max:10|numeric';
          break;
        case 507:
          this.validateTel = 'required|min:7|max:8|numeric';
          break;
        default:
          this.validateTel = 'required|min:7|max:11|numeric';
          break;
      }
    },
    listPaises () {
      this.isLoading = true;
      var data = [];
      var ind = this.indicativo != null ? this.indicativo.replace('+', '') : null;
      _.forEach(callingCode, function (item) {
        if (ind != null && ind === item.calling_code) {
          data.push({
            value: item.calling_code,
            name: item.country,
            label: item.country + ' ( +' + item.calling_code + ' )',
            selected: true
          });
        } else {
          data.push({
            value: item.calling_code,
            name: item.country,
            label: item.country + ' ( +' + item.calling_code + ' )'
          });
        }
      });
      this.paises = data;
      this.isLoading = false;
    },
    indicativeParam (indicativo) {
      let selectIndicative = this.paises.find(value => value.value === parseInt(indicativo));
      this.option = selectIndicative;
    },
    changedPais (indicativo) {
      let data = _(this.paises)
        .filter(pais => {
          if (pais.value === indicativo) {
            return pais;
          }
        })
        .value();
      if (data.length > 0) {
        this.option = data[0];
        let aux2 = null;
        let aux3 = data[0].label;
        this.indicativo = data[0].value;
        this.country = data[0].name;
        let ind = data[0].value;
        _.forEach(BanderaPais, function (item) {
          let data2 = item.country + ' ( +' + ind + ' )';
          if (data2 === aux3) {
            aux2 = item.abbreviation.toLowerCase();
          }
        });
        this.bandera =
          aux2 != null ? 'https://api.precompro.com/banderas/' + aux2 + '.png' : null;
      }
      this.validateTelIndicative(this.option.value);
    },
    changedPaisSelected (indicativo) {
      this.option = indicativo;
      let aux2 = null;
      let aux3 = indicativo.label;
      this.indicativo = indicativo.value;
      this.country = indicativo.name;
      let ind = indicativo.value;
      _.forEach(BanderaPais, function (item) {
        let data2 = item.country + ' ( +' + ind + ' )';
        if (data2 === aux3) {
          aux2 = item.abbreviation.toLowerCase();
        }
      });
      this.bandera = aux2 != null ? 'https://api.precompro.com/banderas/' + aux2 + '.png' : null;
    },
    boolDialog () {
      this.modified = false;
      this.condition = false;
      this.$store.commit(
        whiteLabelTypes.mutations.setShowModalUpdateUser,
        false
      );
    },
    validateBeforeLogin () {
      this.isLoading = true;
      this.$validator.validateAll().then(result => {
        if (result) {
          this.modified = true;
          this.user.indicativo = this.indicativo;
          this.user.bandera = this.bandera;
          this.user.country = this.country;
          this.user.displayName = this.name;
          this.user.phone = this.phone;
          this.user.email = this.email;
          this.user.version = this.versionApp;
          this.user.deviceId = this.deviceId;
          this.user.system = 'select';
          this.updateUser(this.user).then((response) => {
            this.isLoading = false;
            this.boolDialog();
            this.$buefy.snackbar.open({
              duration: 10000,
              message:
                "<i class='fa fa-exclamation-circle alerticon'></i> <strong>" + response.message + '</span></strong>',
              type: 'is-danger',
              position: 'is-top-right',
              actionText: 'X',
              queue: false
            });
          }).catch((error) => {
            this.isLoading = false;
            this.modified = false;
            this.$buefy.snackbar.open({
              duration: 10000,
              message:
                "<i class='fa fa-exclamation-circle alerticon'></i> <strong>" + error.response.data.message + '</span></strong>',
              type: 'is-warning',
              position: 'is-top-right',
              actionText: 'X',
              queue: false
            });
          });
        } else {
          this.isLoading = false;
          this.modified = false;
        }
      });
    },
    showLogin () {
      this.$store.commit(
        whiteLabelTypes.mutations.setShowModalUpdateUser,
        false
      );
    }
  },
  watch: {
    option () {
      this.changedPaisSelected(this.option);
    }
  }
};
</script>

<style lang="scss">
.w-90 {
  width: 90% !important;
}
.hsy-dropdown > .list {
  width: 100% !important;
  height: 80px;
  overflow: scroll;
}
.hsy-dropdown > .selected {
  background-position: 98% center, center center !important;
}

#profile-model {
  .select {
    width: 100%;
  }
  select {
    -moz-text-align-last: right;
    text-align-last: left;
    width: 100%;
  }
}
</style>
