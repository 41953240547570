<template>
  <footer class="footer" v-if="vendor && design && params">
    <div class="container overflow-h">
      <div
        :class="{'social' : vendor.facebook || vendor.instagram || vendor.twitter || vendor.whatsapp}"
        class="build-bottom columns"
      >
        <div
          id="socialRedesFooter"
          v-if="vendor.facebook || vendor.instagram || vendor.twitter || vendor.whatsapp"
        >
          <i @click="openSocial('facebook')" class="icon_facebook" v-if="vendor.facebook"></i>
          <i @click="openSocial('twitter')" class="icon_twitter" v-if="vendor.twitter"></i>
          <i @click="openSocial('instagram')" class="icon_instagram" v-if="vendor.instagram"></i>
          <i @click="openSocial('whatsapp')" class="icon_whatsapp" v-if="vendor.whatsapp"></i>
        </div>
        <div class="column">
          <p class="info-footer">
            <i class="fa fa-phone"></i>
            {{ vendor.phone }} |
            <i class="fa fa-envelope-square"></i>
            {{ vendor.email }}
            <br />
            <i class="fa fa-map-marker"></i>
            {{ vendor.address1 }}
            <span v-if="vendor.address2">{{ vendor.address2 }}</span>
            <br />
            {{ vendor.city.name+', '+vendor.country}}
          </p>
        </div>
        <div class="column footer-logo">
          BUILT BY |
          <i class="icon-mks icon-logo-precompro"></i>
          &nbsp; {{versionApp}}
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import { mapGetters } from 'vuex';
import reservationTypes from '@/store/types/reservation';
export default {
  props: ['designFoo'],
  computed: {
    ...mapGetters({
      reservation: reservationTypes.getters.reservationsUser
    })
  },
  methods: {
    openSocial (social) {
      if (social === 'facebook') {
        window.open(this.vendor.facebook);
      } else if (social === 'twitter') {
        window.open(this.vendor.twitter);
      } else if (social === 'instagram') {
        window.open(this.vendor.instagram);
      } else if (social === 'whatsapp') {
        window.open(`https://wa.me/${this.vendor.whatsapp}`);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.footer {
  padding-top: 20px !important;
}
</style>
